<div class="layout-unauthorized">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-6">
                <div class="logo">
                    <img [src]="loginService.loginLogo" alt="">
                </div>

                <div class="card">
                    <div class="card-body">
                        <h1 class="title title-sm">Bitte Passwort eingeben.</h1>

                        <div class="row justify-content-center">
                            <div class="col-sm-8 text-danger" [innerHtml]="errorMessage" *ngIf="errorMessage"></div>
                            <form class="col-sm-8  reset-password" #resetPasswordForm="ngForm" novalidate (ngSubmit)="resetPassword(resetPasswordForm)">
                                <input
                                    type="hidden"
                                    class="form-control"
                                    name="token"
                                    [(ngModel)]="reset.token"
                                    required>
                                <input
                                    type="hidden"
                                    class="form-control"
                                    name="email"
                                    [(ngModel)]="reset.email"
                                    required>
                                <div class="form-control-holder form-group">
                                    <label>Neues Passwort</label>
                                    <input
                                        type="password"
                                        class="form-control password"
                                        placeholder="Neues Passwort"
                                        name="password"
                                        [(ngModel)]="reset.password"
                                        required
                                        validateEqual="password_confirmation"
                                        reverse="true"
                                        (ngModelChange)="onPasswordChange()"
                                        (focus)="onPasswordFocus()"
                                        (blur)="onPasswordBlur()"
                                        password>

                                    <mrc-validation-errors [invalid]="resetPasswordForm.controls?.password?.invalid"
                                                           [errors]="resetPasswordForm.controls?.password?.errors"
                                                           [dirty]="resetPasswordForm.controls?.password?.dirty"
                                                           [direction]="['md-right']"></mrc-validation-errors>
                                </div>
                                <div class="password-validator-container bg-primary"
                                     *ngIf="!resetPasswordForm.controls?.password?.pristine && !resetPasswordForm.controls?.password?.valid">
                                    <div class="box">
                                        <h4 class="title">Sicherheitsanforderung für Kennwörter:</h4>
                                        <p class="rule">
                                                - 8-15 Zeichen<br>
                                                - mind. 1 Großbuchstabe<br>
                                                - mind. 1 Kleinbuchstabe<br>
                                                - mind. 1 Zahl<br>
                                                - mind. 1 Sonderzeichen<br>
                                        </p>
                                    </div>
                                </div>

                                <div class="form-control-holder form-group">
                                    <label>Neues Passwort wiederholen</label>
                                    <input
                                        type="password"
                                        class="form-control"
                                        placeholder="Neues Passwort wiederholen"
                                        name="password_confirmation"
                                        #confirmPassword="ngModel"
                                        [(ngModel)]="reset.password_confirmation"
                                        validateEqual="password"
                                        required>
                                    <mrc-validation-errors [invalid]="resetPasswordForm.controls?.password_confirmation?.invalid"
                                                           [dirty]="resetPasswordForm.controls?.password_confirmation?.dirty"
                                                           [errors]="resetPasswordForm.controls?.password_confirmation?.errors"
                                                           [direction]="['md-right']"></mrc-validation-errors>
                                </div>

                                <p *ngIf="isMppPortal">
                                    <u>Bitte beachte:</u> Nach dem Zurücksetzen deines Passworts werden alle sensiblen Daten in deinem „Mein Account“-Bereich gelöscht. Um das Portal weiterhin optimal zu nutzen, trage deine Informationen nach dem Login erneut ein.
                                </p>

                                <div class="form-group-btn">
                                    <button type="submit" class="btn btn-primary btn-block" [disabled]="!resetPasswordForm.valid || submitProcess">
                                        <span *ngIf="!submitProcess">Einreichen</span>
                                        <i class="fal fa-sync-alt" *ngIf="submitProcess">
                                            autorenew
                                        </i>
                                    </button>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
